import React, { useMemo } from 'react'
import { get } from 'lodash'
// import { useWindowSizes } from '@hooks/window'
import { Strings } from '../../utils/strings'
import { linkResolver } from '../../utils/linkResolver'

const PdpNavigation = ({ data }) => {

  // console.log('[PdpNavigation.js] data', data)

  const family_name = useMemo(() => (
    get(data, 'family.title[0].text')
  ), [data])

  const family_image = useMemo(() => (
    get(data, 'family.illustration_couple')
  ), [data])

  const product_title = useMemo(() => (
    get(data, 'title[0].text')
  ), [data])
   
  const family_uid = useMemo(() => (
    get(data, 'family._meta.uid')
  ), [data])

  const family_meta = useMemo(() => (
    get(data, 'family._meta')
  ), [data])

  const currentLang = useMemo(() => (
    get(data, 'family._meta.lang')
  ), [data])

  const allStrings = Strings(data)
  // const family_url = "/discover-our-coffees#"+family_uid
  const family_url = linkResolver(family_meta)
  const ourCoffeesUrl = linkResolver("discover-our-coffees", currentLang)

  // console.log('family_url', family_url)

  return(
    <section className="pdp_navigation">
      <div className="row">
        <div className="col col12">
          <div className="p-large font-book hidden_ipad">
            <a href={ourCoffeesUrl}>{allStrings?.plp_refine_dropdown_all_coffees ?? 'All Coffees'} </a>
            / <a href={"/discover-our-coffees#"+family_uid}>
                {family_name} <img src={family_image?.url} alt={family_image?.alt ?? family_name}/>
              </a>
            / <span>{product_title}</span>
          </div>

          <div className="p-large font-book visible_ipad">
            <a href={family_url}>
              <span className="arrow_left"></span>
              {family_name} <img src={family_image?.url} alt={family_image?.alt ?? family_name}/>
            </a>
          </div>
        </div>
      </div>
    </section>
    
  )  
}

export default PdpNavigation
