import React, { useMemo, useState } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PdpNavigation from "../components/slices/PdpNavigation"
import PdpInfo from "../components/slices/PdpInfo"
import PdpShipping from "../components/slices/PdpShipping"
import FarmLayout from "../components/FarmLayout"
import PdpRequestSample from "../components/slices/PdpRequestSample"

const PdpCoffee = ({ page, fazenda }) => {
  // console.log('[PdpCoffee.js] page', page)

  // Toggle Sample Form State

  const [sampleFormState, setSampleFormState] = useState("")
  const toggleSampleForm = () => {
    // setSortButtonState(!sortButtonState)
    if (sampleFormState === "") {
      setSampleFormState(" open")
    } else {
      setSampleFormState("")
    }
  }

  const renderContainer = (container, index) => {
    // console.log('container', container.type)

    if (container && container.type === "text___illustration") {
      return <PdpShipping key={index} data={container} index={index} />
    }

    if (container && container.type === "fazenda") {
      return (
        <FarmLayout
          key={index}
          data={fazenda}
          index={index}
          isFarmPage={false}
        />
      )
    }

    return []
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal">
        <PdpNavigation data={page} />
        <PdpInfo data={page} toggleSampleForm={toggleSampleForm} />
        <PdpRequestSample
          data={page}
          state={sampleFormState}
          toggleSampleForm={toggleSampleForm}
        />

        {page &&
          page?.body?.map((container, index) =>
            renderContainer(container, index)
          )}
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allProducts.edges[0]

  const docFazenda = props.data.prismic.allFazendas.edges[0]

  if (!doc) return null

  const meta = doc.node._meta

  console.log("doc", doc)

  const productTitle = useMemo(() => get(doc, "node.title[0].text"), [doc])

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(
    () => get(doc, "node.seo_image.url") || get(doc, "node.main_image.url"),
    [doc]
  )

  return (
    <Layout pathname={props.path} meta={meta} removeImgRule>
      <SEO
        title={
          seoTitle
            ? seoTitle
            : productTitle
            ? productTitle
            : "Transparent, Sustainable and Direct"
        }
        description={seoText}
        image={seoImage}
      />
      <PdpCoffee page={doc.node} fazenda={docFazenda?.node} />
    </Layout>
  )
}

export const query = graphql`
  query pdpCoffee($lang: String, $uid: String, $fazenda_uid: String) {
    prismic {
      allProducts(lang: $lang, uid: $uid) {
        edges {
          node {
            seo_title
            seo_meta
            seo_image
            _meta {
              uid
              lang
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            family {
              ... on PRISMIC_Coffee_family {
                _meta {
                  uid
                  lang
                  type
                }
                title
                description
                illustration_individual
                illustration_outline_couple
                illustration_couple
              }
            }
            title
            sku
            notes {
              note
            }
            # harvest
            # current_location
            # bags_available
            # harvest_year
            # min_order
            # packaging
            # processing
            # status
            # variety
            # altitude
            details {
              title1
              text
              show_in_mini_card
            }
            main_image
            form_title
            form_intro
            acidity_strength
            aroma_strength
            bitter_strength
            body_strength
            specs {
              name
              description1
            }
            graph_acidity_label
            graph_aroma_label
            graph_bitter_label
            graph_body_label
            body1 {
              ... on PRISMIC_ProductBody1Sample_form {
                type
                label
                primary {
                  target_location
                  hubspot_portal_id1
                  hubspot_form_id1
                }
              }
            }
            certifications {
              certification
            }
            fazenda {
              ... on PRISMIC_Fazenda {
                title
                _linkType
                country {
                  ... on PRISMIC_Country {
                    title
                    flag
                  }
                }
              }
            }
            body {
              ... on PRISMIC_ProductBodyText___illustration {
                type
                label
                primary {
                  title1
                  text
                  illustration
                }
              }
              ... on PRISMIC_ProductBodyFazenda {
                type
                label
              }
            }
          }
        }
      }
      allFazendas(lang: $lang, uid: $fazenda_uid) {
        edges {
          node {
            _meta {
              uid
              lang
              type
            }
            body {
              ... on PRISMIC_FazendaBodyText___image {
                type
                label
                primary {
                  image
                  image_aligment
                  text
                }
              }
              ... on PRISMIC_FazendaBodyImages_editorial {
                type
                label
                primary {
                  image_1
                  image_2
                  image_3
                  layout
                }
              }
              ... on PRISMIC_FazendaBodyRelated_products {
                type
                label
                primary {
                  title1
                  products
                }
                fields {
                  related_product {
                    ... on PRISMIC_Product {
                      _meta {
                        uid
                        lang
                        type
                      }
                      family {
                        ... on PRISMIC_Coffee_family {
                          title
                          description
                          illustration_individual
                        }
                      }
                      title
                      sku
                      notes {
                        note
                      }
                      # harvest
                      # current_location
                      # bags_available
                      main_image
                      fazenda {
                        ... on PRISMIC_Fazenda {
                          title
                          _linkType
                          _meta {
                            uid
                          }
                          country {
                            ... on PRISMIC_Country {
                              title
                              flag
                            }
                          }
                        }
                      }
                      # harvest_year
                      # min_order
                      # packaging
                      # processing
                      # status
                      # variety
                      form_title
                      form_intro
                      body1 {
                        ... on PRISMIC_ProductBody1Sample_form {
                          type
                          label
                          primary {
                            target_location
                            hubspot_portal_id1
                            hubspot_form_id1
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            characteristics {
              title1
              text
            }
            country {
              ... on PRISMIC_Country {
                title
                flag
              }
            }
            title
            show_related_products
            main_image
            local_location
            intro
          }
        }
      }
    }
  }
`
