import React, { useMemo } from 'react'
import { get } from 'lodash'
// import { useWindowSizes } from '@hooks/window'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const PdpShipping = ({ data }) => {

  // console.log('[PdpShipping.js] data', data)

  const title = useMemo(() => (
    get(data, 'primary.title1[0].text')
  ), [data])

  const text = useMemo(() => (
    get(data, 'primary.text')
  ), [data])

  const illustration = useMemo(() => (
    get(data, 'primary.illustration')
  ), [data])

  return(
    <section className="pdp_shipping">
      
      <div className="row pdp_shipping__row">
        <div className="col col6">

          <div className="pdp_shipping__box">
            {title ?
              <h3>{title}</h3>
            : ''}

            <div className="font-book">
              <RichText
                render={text}
                linkResolver={linkResolver}
              />
            </div>            
          </div>
          
        </div>
      </div>

      {illustration ? 
      <div className="pdp_shipping__background">
        <img src={illustration?.url} alt={illustration?.alt ?? title} />
      </div>
      : null}
      
    </section>
    
  )  
}

export default PdpShipping
